import React from "react";

import { faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReferrerCard = ({ handleYes, handleNo }) => {
  return (
    <div className="refer flex justify-between items-center border p-3 rounded-lg mt-6">
      <div className="flex gap-2">
        <FontAwesomeIcon icon={faUser} className="icons text-xl" />
        <p className="text-black font-semibold">
          Did someone refer you to this platform?
        </p>
      </div>
      <div className="flex gap-2">
        <div
          className="yes font-semibold px-3 py-1 border rounded-xl cursor-pointer text-xs"
          style={{ borderColor: "#2ca721" }}
          onClick={handleYes}
        >
          Yes
        </div>
        <div
          className="yes font-semibold px-3 py-1 border rounded-xl cursor-pointer text-xs"
          style={{ borderColor: "#2ca721" }}
          onClick={handleNo}
        >
          No
        </div>
      </div>
    </div>
  );
};

export default ReferrerCard;
