/* eslint-disable no-restricted-globals */
import React, { useState, useReducer } from 'react';
import { Link, useLocation } from "react-router-dom";
import Logo from "../images/logo.webp";
import RequiredRegistrationCard from "./RequiredRegistrationCard";
import ReferrerCard from "./ReferrerCard";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";
import { logoutService } from "../services/LogoutService";
import LogoutModel from "./LogoutModel";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
const SidebarNav = () => {
  const location = useLocation();
  const [modal, toggleModal] = useReducer((prev) => !prev, false);

  const [referrerModal, toggleReferrerModal] = useReducer(
    (prev) => !prev,
    false
  );
  const [showLogoutModal, toggleShowLogoutModal] = useReducer(
    (prev) => !prev,
    false
  );
  const confirmLogout = () => {
    toggleShowLogoutModal();
    logoutService("Session");
  };
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen((prev) => !prev);
  return (
    <>
      <header className="fixed top-0 w-full bg-white shadow-md z-50 flex items-center justify-between p-4 lg:p-6">
        <div className="flex items-center">
          <img src={Logo} alt="Logo" className="h-12" />
        </div>
        <div className="lg:hidden z-50">
          {/* Mobile Menu Toggle Button */}
          <button onClick={toggleMenu} className="text-green-600 focus:outline-none">
            <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} size="2x" />
          </button>
        </div>
        <div className="hidden md:flex items-center me-4">
          <button
            className="border-green-600 text-green-600 py-2 px-10 rounded cursor-pointer border"
            onClick={toggleShowLogoutModal}
          >
            Logout
          </button>
        </div>
      </header>
      <nav
        className={`fixed top-0 left-0 h-full bg-gray-100 p-5 pt-16 pb-20 lg:w-1/3 lg:static transition-transform duration-300 transform ${
          isMenuOpen ? "translate-x-0 w-full" : "-translate-x-full"
        } lg:translate-x-0 z-40`} // Full width for mobile view
      >
        <div className="bg-gray-100 w-full h-full">
          <div className="steps bg-white shadow-lg rounded-lg pt-14 pb-4 mb-6">
            <h2 className="text-xl text-gray-800 mb-3 px-5">
              Complete your registration
            </h2>
            <hr className="border-gray-300" />
            <ul className="mt-5 space-y-7">
              <Link to="/register" className="doc-content">
                <li
                  className={
                    location.pathname === "/register"
                      ? "flex px-5 py-2 items-center space-x-3 bg-gray-100"
                      : "flex px-5 py-2 items-center space-x-3 hover:bg-gray-50"
                  }
                >
                  <div className="text-2xl">&#127828;</div>
                  <div>
                    Restaurant information
                    <p className="text-sm font-bold text-gray-500">
                      Name, location and contact number
                    </p>
                  </div>
                </li>
              </Link>
              <Link to="/menu-operational" className="doc-content">
                <li
                  className={
                    location.pathname === "/menu-operational"
                      ? "flex px-5 py-2 items-center space-x-3 bg-gray-100"
                      : "flex px-5 py-2 items-center space-x-3 hover:bg-gray-50"
                  }
                >
                  <div className="text-2xl">&#128221;</div>
                  <div>Menu and Operational Details</div>
                </li>
              </Link>
              <Link to="/restaurant-documents" className="doc-content">
                <li
                  className={
                    location.pathname === "/restaurant-documents"
                      ? "flex px-5 py-2 items-center space-x-3 bg-gray-100"
                      : "flex px-5 py-2 items-center space-x-3 hover:bg-gray-50"
                  }
                >
                  <div className="text-2xl">&#128194;</div>
                  <div>Restaurant documents</div>
                </li>
              </Link>
              <Link to="/contract" className="doc-content">
                <li
                  className={
                    location.pathname === "/contract"
                      ? "flex px-5 py-2 items-center space-x-3 bg-gray-100"
                      : "flex px-5 py-2 items-center space-x-3 hover:bg-gray-50"
                  }
                >
                  <div className="text-2xl">&#128196;</div>
                  <div>Partner Contract</div>
                </li>
              </Link>
            </ul>
          </div>
          <RequiredRegistrationCard onClickCard={toggleModal} />
          <ReferrerCard handleYes={toggleReferrerModal} handleNo={() => {}} />
              {/* Logout button in the sidebar for mobile view */}
          <div className="mt-10 flex justify-center md:hidden">
            <button
              className="border-green-600 text-green-600 py-2 px-10 rounded cursor-pointer border"
              onClick={toggleShowLogoutModal}
            >
              Logout
            </button>
          </div>
          {modal && <RequiredRegistrationDetails toggleModal={toggleModal} />}
          {referrerModal && (
            <ReferrerModal
              toggleReferModal={toggleReferrerModal}
              onClickSuccess={() => {
                toggleReferrerModal();
              }}
            />
          )}
          {showLogoutModal && (
            <LogoutModel
              onClickCancel={toggleShowLogoutModal}
              onClickLogout={confirmLogout}
            />
          )}
         
        </div>
      </nav>
    </>
  );
};

export default SidebarNav;





