
import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { GrGallery } from "react-icons/gr";
import { FaCalendarAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import UploadGuidelinesModal from "../components/UploadGuidelinesModal";
import UploadGstGuidelines from "./UploadGstGuidelines";
import { logoutService } from "../services/LogoutService";
import UploadFssaiGuidelines from "./UploadFssaiGuidelines";
import { fetchPanUrl, deletePanUrl, fetchGstUrl, deleteGstUrl, fetchFssaiUrl, deleteFssaiUrl, confirmGstUpload, confirmFssaiUpload, uploadPanImage, uploadGstImage, uploadFssaiImage, getVerificationStatus, updateRestaurantDocuments, confirmPanUpload, } from "../services/Api";
import imageCompression from "browser-image-compression";
import "./Registration.css";
import LogoutModel from "./LogoutModel";
import { APP_NAME } from "../constants";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";
import { verifyPAN, verifyGST, verifyFSSAI, verifyBANK } from "../services/Api"; // Import API functions

const RestaurantDocuments = () => {
  const [showModal, setShowModal] = useState(false);
  const [showReferModal, setShowReferModal] = useState(false);
  const [panNumber, setPanNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [address, setAddress] = useState("");
  const [panVerified, setPanVerified] = useState(false);
  const [panInvalid, setPanInvalid] = useState(false);
  const [gstNumber, setGstNumber] = useState("");
  const [isGstRegistered, setIsGstRegistered] = useState(null);
  const [fssaiNumber, setFssaiNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState(null);
  const [uploadedPanImage, setUploadedPanImage] = useState(null);
  const [uploadedGstImage, setUploadedGstImage] = useState(null);
  const [uploadedFssaiImage, setUploadedFssaiImage] = useState(null);
  const [, setPanVerificationStatus] = useState(null);
  const [gstVerificationStatus, setGstVerificationStatus] = useState(null);
  const [fssaiVerificationStatus, setFssaiVerificationStatus] = useState(null);
  const [isPanModalOpen, setIsPanModalOpen] = useState(false);
  const [isGSTModalOpen, setIsGSTModalOpen] = useState(false);
  const [isFssaiModalOpen, setIsFssaiModalOpen] = useState(false);
  const datepickerRef = useRef(null);
  const [accountNumber, setAccountNumber] = useState("");
  const [reEnterAccountNumber, setReEnterAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [accountType, setAccountType] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const navigate = useNavigate();
  const restaurantId = localStorage.getItem("restaurant_Id");

  const validateForm = () => {
    const isAccountNumberValid =
      accountNumber.length >= 9 && accountNumber.length <= 18;
    const isReEnterAccountNumberValid = accountNumber === reEnterAccountNumber;
    const isIfscCodeValid = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifscCode);
    const isAccountTypeValid = accountType !== "";
    const isPanDetailsValid = panVerified && fullName && address;
    const isFssaiDetailsValid =
      fssaiNumber && expiryDate && fssaiVerificationStatus === "verified";
    const isGstDetailsValid =
      isGstRegistered !== "yes" ||
      (uploadedGstImage && gstVerificationStatus === "verified");

    setIsFormValid(isAccountNumberValid && isReEnterAccountNumberValid && isIfscCodeValid && isAccountTypeValid && isPanDetailsValid && isFssaiDetailsValid && isGstDetailsValid);
  };

  useEffect(() => {
    validateForm();
  }, [panNumber, fullName, address, isGstRegistered, fssaiNumber, expiryDate, accountNumber, reEnterAccountNumber, ifscCode, accountType]);

  useEffect(() => {
    fetchDocumentUrls();
  }, []);

  const fetchDocumentUrls = async () => {
    try {
      const panData = await fetchPanUrl(restaurantId);
      const gstData = await fetchGstUrl(restaurantId);
      const fssaiData = await fetchFssaiUrl(restaurantId);

      setUploadedPanImage(panData.panUrl || null);
      setUploadedGstImage(gstData.gstUrl || null);
      setUploadedFssaiImage(fssaiData.fssaiUrl || null);
    } catch (error) {
      console.error("Error fetching document URLs:", error);
    }
  };

  const toggleModal = () => setShowModal(!showModal);
  const toggleReferModal = () => setShowReferModal(!showReferModal);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const closeVerificationModal = () => setVerificationModal(false);
  const [verificationModal, setVerificationModal] = useState(false); // State for verification modal
  const [verificationMessage, setVerificationMessage] = useState(""); // State for verification message

  useEffect(() => {
    const fetchVerificationStatus = async () => {
      try {
        // Replace with actual restaurantId
        const response = await getVerificationStatus(restaurantId);
        console.log("Verification Status Response:", response); // Log response

        let verifiedFields = [];

        // Check and handle verification status
        if (response.isPanVerified) {
          setPanVerified(true);
          verifiedFields.push("PAN details are verified.");
        } else {
          setPanNumber("");
          setFullName("");
          setAddress("");
        }

        if (response.isGstVerified) {
          setIsGstRegistered("yes");
          verifiedFields.push("GST details are verified.");
        } else {
          setGstNumber("");
        }

        if (response.isBankVerified) {
          setAccountNumber("");
          setReEnterAccountNumber("");
          setIfscCode("");
          setAccountType("");
          verifiedFields.push("Bank details are verified.");
        }

        // Show verification message modal if any verification is true
        if (verifiedFields.length > 0) {
          setVerificationMessage(verifiedFields.join(" "));
          setVerificationModal(true);
        }
      } catch (error) {
        console.error("Error fetching verification status:", error);
      }
    };

    fetchVerificationStatus(); // Call the verification status API on component mount
  }, []);



  const confirmLogout = () => {
    setShowLogoutModal(false);
    logoutService("Session");
  };

  // PAN Verification
  const handlePanVerify = async () => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (panRegex.test(panNumber)) {
      try {
        await verifyPAN(panNumber, fullName);
        setPanVerified(true);
        setPanInvalid(false);
        setPanVerificationStatus("verified");
      } catch (error) {
        console.error("PAN verification failed:", error);
        setPanInvalid(true);
        setPanVerificationStatus("failed");
      }
    } else {
      setPanVerified(false);
      setPanInvalid(true);
    }
  };

  // GST Verification
  const handleGstVerify = async () => {
    if (isGstRegistered === "yes" && gstNumber.length === 15) {
      try {
        await verifyGST(gstNumber, address); // Pass the correct gstNumber and address
        setGstVerificationStatus("verified");
      } catch (error) {
        console.error("GST verification failed:", error);
        setGstVerificationStatus("failed");
      }
    }
  };

  // FSSAI Verification
  const handleFssaiVerify = async () => {
    if (fssaiNumber.length === 14) {
      try {
        await verifyFSSAI(fssaiNumber, expiryDate); // Pass the correct fssaiNumber and expiryDate
        setFssaiVerificationStatus("verified");
      } catch (error) {
        console.error("FSSAI verification failed:", error);
        setFssaiVerificationStatus("failed");
      }
    }
  };

  // BANK Verification
  const handleBankVerify = async () => {
    try {
      await verifyBANK(accountNumber, ifscCode, accountType);
      alert("Bank verification succeeded");
    } catch (error) {
      console.error("Bank verification failed:", error);
      alert("Bank verification failed");
    }
  };

  const handleImageUpload = async (
    e,
    uploadFunction,
    setImageState,
    confirmUploadFunction
  ) => {
    const file = e.target.files[0];
    let metadataId;

    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        fileType: "image/webp",
      };

      const webpFile = await imageCompression(file, options);
      const { presignedURL, metadataId: id } = await uploadFunction("webp", restaurantId);
      metadataId = id;

      await fetch(presignedURL, {
        method: "PUT",
        headers: {
          "Content-Type": "image/webp",
        },
        body: webpFile,
      });

      const response = await confirmUploadFunction(metadataId, true, restaurantId);

      if (response.status === "success") {
        // Set the image state with the publicUrl from the response
        setImageState(response.publicUrl);


        alert("Image uploaded and confirmed successfully");
      }
      return URL.createObjectURL(webpFile);
    } catch (error) {
      console.error("Error uploading file:", error);
      if (metadataId) {
        await confirmUploadFunction(metadataId, false, restaurantId);
      }
      alert("Failed to upload the image");
    }
  };

  const handlePanImageUpload = async (e) => {
    const imageUrl = await handleImageUpload(
      e,
      uploadPanImage,
      setUploadedPanImage,
      confirmPanUpload
    );
    setUploadedPanImage(imageUrl);
    setPanVerificationStatus("verified");
  };

  const handleGstImageUpload = async (e) => {
    const imageUrl = await handleImageUpload(
      e,
      uploadGstImage,
      confirmGstUpload
    );
    setUploadedGstImage(imageUrl);
    setGstVerificationStatus("verified");
  };

  const handleFssaiImageUpload = async (e) => {
    const imageUrl = await handleImageUpload(
      e,
      uploadFssaiImage,
      confirmFssaiUpload
    );
    setUploadedFssaiImage(imageUrl);
    setFssaiVerificationStatus("verified");
  };

  // const handleDeleteImage = async (deleteFunction, imageUrlSetter, verificationStatusSetter) => {
  //   try {
  //     await deleteFunction(restaurantId); // Delete specific document
  //     imageUrlSetter(null); // Reset only the specific image state
  //     verificationStatusSetter(null); // Reset only the specific verification status
  //     alert("Image deleted Successfully");
  //   } catch (error) {
  //     console.error("Error deleting image:", error);
  //   }
  // };

  const handleDeletePanImage = async () => {
    try {
      await deletePanUrl(uploadedPanImage, restaurantId); // Delete PAN from the server
      setUploadedPanImage(null); // Only reset PAN image
      setPanVerificationStatus(null); // Reset PAN verification status
      alert("PAN Image deleted Successfully");
    } catch (error) {
      console.error("Error deleting PAN image:", error);
    }
  };

  const handleDeleteGstImage = async () => {
    try {
      await deleteGstUrl(restaurantId); // Delete GST from the server
      setUploadedGstImage(null); // Only reset GST image
      setGstVerificationStatus(null); // Reset GST verification status
      alert("GST Image deleted Successfully");
    } catch (error) {
      console.error("Error deleting GST image:", error);
    }
  };

  const handleDeleteFssaiImage = async () => {
    try {
      await deleteFssaiUrl(restaurantId); // Delete FSSAI from the server
      setUploadedFssaiImage(null); // Only reset FSSAI image
      setFssaiVerificationStatus(null); // Reset FSSAI verification status
      alert("FSSAI Image deleted Successfully");
    } catch (error) {
      console.error("Error deleting FSSAI image:", error);
    }
  };


  const handleAccountNumberChange = (e) => {
    setAccountNumber(e.target.value);
  };

  const handleReEnterAccountNumberChange = (e) => {
    setReEnterAccountNumber(e.target.value);
  };

  const handleIfscCodeChange = (e) => {
    setIfscCode(e.target.value);
  };

  const handleAccountTypeChange = (e) => {
    setAccountType(e.target.value);
  };

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);
   
  const handleSaveAsDraft = () => {

    // Stores the restaurant documents data in an object
    const restaurantDocumentsData = {
      panNumber: panNumber,
      panFullName: fullName,
      gstNumber: gstNumber,
      gstAddress: address,
      fssaiNumber: fssaiNumber,
      fssaiValidTo: expiryDate ? expiryDate.toISOString().split('T')[0] : null, // Format date to YYYY-MM-DD
      bankAccountNumber: accountNumber,
      bankIfscCode: ifscCode,
      accountType: accountType,
    };

    // Store the data in localStorage
    localStorage.setItem('restaurantDocumentsData', JSON.stringify(restaurantDocumentsData));

    // Log to the console
    console.log('Restaurant Documents Data:', restaurantDocumentsData);

  };

  const handleSubmit = async () => {
    try {
      const documentData = {
        panNumber,
        panFullName: fullName,
        gstNumber,
        gstAddress: address,
        fssaiNumber,
        fssaiValidTo: expiryDate ? expiryDate.toISOString().split('T')[0] : null,  // Convert date to "YYYY-MM-DD"
        bankAccountNumber: accountNumber,
        bankIfscCode: ifscCode,
        accountType,
      };

      // Call the API to update the restaurant documents
      await updateRestaurantDocuments(documentData);

      alert("Restaurant documents updated successfully");
      navigate("/contract");  // Navigate to the next page if successful
    } catch (error) {
      console.error("Error updating restaurant documents:", error);
      alert("Failed to update restaurant documents");
    }
  };

  return (
    <div className="registration bg-gray-100 h-screen flex">
      <main className="pt-20 pb-20 flex w-full">
        <div className="content flex-1 overflow-y-auto bg-gray-100">
          <div className="container mx-auto p-6">
            <h3 className="text-3xl font-bold">Restaurant Documents</h3>
            {/* PAN Details Section */}
            {verificationModal && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded shadow-lg">
                  <h2 className="text-xl font-bold mb-4">Verification Status</h2>
                  <p>{verificationMessage}</p>
                  <button onClick={closeVerificationModal} className="mt-4 bg-green-500 text-white px-4 py-2 rounded">
                    Close
                  </button>
                </div>
              </div>
            )}
            <div className="bg-white p-6 rounded-lg shadow-md mt-5">
              <h2 className="text-2xl font-bold mb-4">PAN details</h2>
              <p className="text-gray-600 mb-6">
                Enter the PAN details of the person or company who legally owns
                the restaurant
              </p>
              <div className="grid grid-cols-1 gap-6 mb-6">
                <div className="flex items-center space-x-4">
                  <div className="flex-1">
                    <label htmlFor="panNumber" className="block text-sm font-medium text-gray-700" > PAN number* </label>
                    <input
                      type="text"
                      id="panNumber"
                      value={panNumber}
                      maxLength={10}
                      onChange={(e) => {
                        let inputValue = e.target.value.toUpperCase().replace(/\s+/g, ''); // Remove whitespace and uppercase

                        // Restrict first 5 characters to alphabets and next 4 to digits
                        if (inputValue.length <= 5) {
                          inputValue = inputValue.replace(/[^A-Z]/g, ''); // Only allow alphabets in first 5 chars
                        } else if (inputValue.length <= 9) {
                          inputValue = inputValue.slice(0, 5) + inputValue.slice(5).replace(/[^0-9]/g, ''); // Allow digits from 6th to 9th char
                        } else {
                          inputValue = inputValue.slice(0, 9) + inputValue.slice(9).replace(/[^A-Z]/g, ''); // Restrict 10th char to an alphabet
                        }

                        setPanNumber(inputValue);
                      }}
                      className={`mt-1 block w-full p-2 border ${panInvalid ? "border-red-500" : "border-gray-300"
                        } rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500`}
                    />
                    {panInvalid && (
                      <p className="text-red-500 text-sm mt-1">Invalid PAN number</p>
                    )}
                  </div>
                  <div className="flex-1">
                    <label htmlFor="fullName" className="block text-sm font-medium text-gray-700" > Full name as per PAN* </label>
                    <input
                      type="text"
                      id="fullName"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value.toUpperCase())}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                    />
                  </div>
                  {panNumber.length === 10 && (
                    <button
                      onClick={handlePanVerify}
                      className="mt-6 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                    >
                      Verify
                    </button>
                  )}
                </div>
                {panVerified && (
                  <div className="text-green-500 flex items-center">
                    <span className="material-icons"> Pan Details Verified </span>
                  </div>
                )}
              </div>

              <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center">
                {uploadedPanImage ? (
                  <div className="relative">
                    <img src={uploadedPanImage} alt="Uploaded" className="mx-auto mb-4 max-h-48" />
                    <button
                      className="absolute top-2 right-2 text-red-500 bg-white rounded-full p-2 shadow-lg"
                      onClick={() =>
                        handleDeletePanImage(deletePanUrl(uploadedPanImage, restaurantId),
                          setUploadedPanImage,
                          setPanVerificationStatus
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </button>
                  </div>
                ) : (
                  <div className="flex justify-center items-center mb-4">
                    <GrGallery className="text-green-500 text-3xl" />
                  </div>
                )}
                <p className="text-green-500 font-bold">Upload your PAN</p>
                <p className="text-gray-600">jpeg, png or pdf formats up-to 5MB</p>
                <input type="file" onChange={handlePanImageUpload} className="mt-2" />
              </div>

              <div className="mt-4 text-center">
                <button onClick={() => setIsPanModalOpen(true)} className="text-green-500 underline" > Guidelines to upload PAN </button>
              </div>
            </div>

            <UploadGuidelinesModal
              isOpen={isPanModalOpen}
              onClose={() => setIsPanModalOpen(false)}
            />

            <div className="bg-white rounded-lg shadow-md mt-6">
              <div className="container mx-auto p-6">
                <div>
                  <h4 className="text-xl font-bold mb-4">
                    Full address of your registered business*
                  </h4>

                  <input
                    type="text"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>
            </div>

            {/* GST Details Section */}
            <div className="bg-white p-6 rounded-lg shadow-md mt-6">
              <p className="text-sm text-gray-600 mb-2"> Note: Please first enter and verify all the PAN details above. </p>
              <h2 className="text-2xl font-bold mb-2"> GST details (if applicable) </h2>
              <p className="text-gray-600 mb-6"> This should be linked to the PAN provided earlier for tax calculations </p>
              <p className="mb-4">Are you GST registered?</p>
              <div className="flex items-center mb-6">
                <label className="flex items-center mr-4">
                  <input type="radio" name="gstRegistered" value="yes" checked={isGstRegistered === "yes"} onChange={() => setIsGstRegistered("yes")} className="form-radio text-blue-500" />
                  <span className="ml-2">Yes</span>
                </label>
                <label className="flex items-center">
                  <input type="radio" name="gstRegistered" value="no" checked={isGstRegistered === "no"} onChange={() => setIsGstRegistered("no")} className="form-radio text-blue-500" />
                  <span className="ml-2">No</span>
                </label>
              </div>
              {isGstRegistered === "yes" && (
                <>
                  <div className="flex-1 mt-4">
                    <label htmlFor="gstNumber" className="block text-sm font-medium text-gray-700 mr-4" > GST Number* </label>
                    <div className='flex items-center'>
                      <input
                        type="text"
                        id="gstNumber"
                        value={gstNumber}
                        maxLength={15} // Restrict input to 15 characters
                        onChange={(e) => {
                          let inputValue = e.target.value.toUpperCase().replace(/\s+/g, ''); // Remove whitespace and uppercase

                          // Restrict input according to GST format
                          if (inputValue.length <= 2) {
                            inputValue = inputValue.replace(/[^0-9]/g, ''); // First 2 characters should be numbers (state code)
                          } else if (inputValue.length <= 12) {
                            inputValue = inputValue.slice(0, 2) + inputValue.slice(2).replace(/[^A-Z0-9]/g, ''); // Next 10 characters: PAN format
                          } else if (inputValue.length === 13) {
                            inputValue = inputValue.slice(0, 12) + inputValue.slice(12).replace(/[^A-Z0-9]/g, ''); // 13th character: Number or Alphabet
                          } else if (inputValue.length === 14) {
                            inputValue = inputValue.slice(0, 13) + inputValue.slice(13).replace(/[^Z]/g, ''); // 14th character should always be 'Z'
                          } else if (inputValue.length === 15) {
                            inputValue = inputValue.slice(0, 14) + inputValue.slice(14).replace(/[^A-Z0-9]/g, ''); // 15th character: Checksum digit (alphabet or number)
                          }

                          setGstNumber(inputValue); // Set the formatted value
                        }}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                      />
                      {gstNumber.length === 15 && (
                        <button onClick={handleGstVerify} className="ml-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600" > Verify </button>
                      )}
                    </div>
                  </div>

                  <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center mt-6">
                    {uploadedGstImage ? (
                      <div className="relative">
                        <img src={uploadedGstImage} alt="Uploaded" className="mx-auto mb-4 max-h-48" />
                        <button
                          className="absolute top-2 right-2 text-red-500 bg-white rounded-full p-2 shadow-lg"
                          onClick={() =>
                            handleDeleteGstImage(deleteGstUrl(uploadedGstImage, restaurantId),
                              setUploadedGstImage,
                              setGstVerificationStatus
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    ) : (
                      <>
                        <div className="flex justify-center items-center mb-4">
                          <GrGallery className="text-green-500 text-3xl" />
                        </div>
                        <p className="text-green-500 font-bold"> Upload your GST Certificate </p>
                        <p className="text-gray-600"> jpeg, png or pdf formats up-to 5MB </p>
                      </>
                    )}
                    <input type="file" onChange={handleGstImageUpload} className="mt-2" />
                  </div>
                  <div className="mt-4 text-center">
                    <button onClick={() => setIsGSTModalOpen(true)} className="text-green-500 underline" > Guidelines to upload GST </button>
                  </div>
                  <UploadGstGuidelines isOpen={isGSTModalOpen} onClose={() => setIsGSTModalOpen(false)} />
                </>
              )}
            </div>

            {/* FSSAI Details Section */}
            <div className="container mx-auto mt-5">
              <h1 className="text-3xl font-bold mb-4">FSSAI details</h1>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <h2 className="text-2xl font-bold mb-4">FSSAI details</h2>
                <p className="text-gray-600 mb-6">
                  This is required to comply with regulations on food safety
                </p>
                <div className="bg-blue-50 p-4 rounded-lg mb-6">
                  <h3 className="font-bold text-lg mb-2">FSSAI requirements</h3>
                  <ul className="list-disc list-inside">
                    <li>
                      The name on the FSSAI certificate should match either the
                      restaurant's name or the name on the PAN card.
                    </li>
                    <li>
                      The address on the FSSAI certificate should match the
                      restaurant's address.
                    </li>
                  </ul>
                </div>
                <div className="grid grid-cols-1 gap-6 mb-6">
                  <div className="flex-1">
                    <label
                      htmlFor="fssaiNumber"
                      className="block text-sm font-medium text-gray-700 mr-4"
                    >
                      FSSAI Number*
                    </label>
                    <div className='flex items-center'>
                      <input
                        type="text"
                        id="fssaiNumber"
                        value={fssaiNumber}
                        maxLength={14} // Restrict input to 14 characters
                        onChange={(e) => {
                          let inputValue = e.target.value.replace(/\D/g, ''); // Remove any non-numeric characters
                          if (inputValue.length <= 14) {
                            setFssaiNumber(inputValue); // Set the formatted value
                          }
                        }}
                        className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                      />
                      {fssaiNumber.length === 14 && (
                        <button
                          onClick={handleFssaiVerify}
                          className="ml-4 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                        >
                          Verify
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="flex-1 relative">
                    <label
                      htmlFor="expiryDate"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Expiry date*
                    </label>
                    <div className="mt-1 relative">
                      <DatePicker
                        ref={datepickerRef}
                        selected={expiryDate}
                        onChange={(date) => setExpiryDate(date)}
                        dateFormat="dd/MM/yyyy"
                        className="block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                      />
                      <FaCalendarAlt
                        className="absolute top-3 left-44 text-gray-400 cursor-pointer"
                        onClick={() => datepickerRef.current.setFocus()}
                      />
                    </div>
                  </div>
                </div>
                <div className="border-2 border-dashed border-gray-300 p-6 rounded-lg text-center mb-6">
                  {uploadedFssaiImage ? (
                    <div className="relative">
                      <img
                        src={uploadedFssaiImage}
                        alt="Uploaded"
                        className="mx-auto mb-4 max-h-48"
                      />
                      <button
                        className="absolute top-2 right-2 text-red-500 bg-white rounded-full p-2 shadow-lg"
                        onClick={() =>
                          handleDeleteFssaiImage(deleteFssaiUrl(uploadFssaiImage, restaurantId),
                            setUploadedFssaiImage,
                            setFssaiVerificationStatus
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  ) : (
                    <>
                      <div className="flex justify-center items-center mb-4">
                        <GrGallery className="text-green-500 text-3xl" />
                      </div>
                      <p className="text-green-500 font-bold">
                        Upload your FSSAI license
                      </p>
                      <p className="text-gray-600">
                        jpeg, png or pdf formats up-to 5MB
                      </p>
                    </>
                  )}
                  <input
                    type="file"
                    onChange={handleFssaiImageUpload}
                    className="mt-2"
                  />
                </div>
                <div className="mt-4 text-center">
                  <button
                    onClick={() => setIsFssaiModalOpen(true)}
                    className="text-green-500 underline"
                  >
                    FSSAI guidelines to upload
                  </button>
                </div>
              </div>
              <UploadFssaiGuidelines
                isOpen={isFssaiModalOpen}
                onClose={() => setIsFssaiModalOpen(false)}
              />
            </div>

            {/* Bank Account Details Section */}
            <div className="container mx-auto mt-5">
              <h1 className="text-3xl font-bold mb-4">Bank account details</h1>
              <p className="text-gray-600 mb-4">
                This is where {APP_NAME} will deposit your earnings
              </p>
              <div className="bg-white p-6 rounded-lg shadow-md">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
                  <div>
                    <label
                      htmlFor="accountNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Bank account number*
                    </label>
                    <input
                      type="text"
                      id="accountNumber"
                      value={accountNumber}
                      onChange={handleAccountNumberChange}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                    />
                    {accountNumber.length > 0 &&
                      (accountNumber.length < 9 ||
                        accountNumber.length > 18) && (
                        <p className="text-red-500 text-sm mt-1">
                          Invalid account number
                        </p>
                      )}
                  </div>
                  <div>
                    <label
                      htmlFor="reEnterAccountNumber"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Re-enter bank account number*
                    </label>
                    <input
                      type="text"
                      id="reEnterAccountNumber"
                      value={reEnterAccountNumber}
                      onChange={handleReEnterAccountNumberChange}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                    />
                    {reEnterAccountNumber.length > 0 &&
                      reEnterAccountNumber !== accountNumber && (
                        <p className="text-red-500 text-sm mt-1">
                          Account numbers do not match
                        </p>
                      )}
                  </div>
                  <div>
                    <label
                      htmlFor="ifscCode"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Enter IFSC code*
                    </label>
                    <input
                      type="text"
                      id="ifscCode"
                      value={ifscCode}
                      onChange={handleIfscCodeChange}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                    />
                    {ifscCode.length > 0 &&
                      !/^[A-Z]{4}0[A-Z0-9]{6}$/.test(ifscCode) && (
                        <p className="text-red-500 text-sm mt-1">
                          Invalid IFSC code
                        </p>
                      )}
                  </div>
                  <div>
                    <label
                      htmlFor="accountType"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Account Type
                    </label>
                    <select
                      id="accountType"
                      value={accountType}
                      onChange={handleAccountTypeChange}
                      className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                    >
                      <option value="">Select Account Type</option>
                      <option value="savings">Savings</option>
                      <option value="current">Current</option>
                    </select>
                    {accountType === "" && (
                      <p className="text-red-500 text-sm mt-1">
                        Please select account type
                      </p>
                    )}
                  </div>
                </div>
                <button
                  disabled={!isFormValid}
                  className={`mt-6 w-full py-2 rounded ${isFormValid
                    ? "bg-green-500 text-white"
                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                    }`}
                >
                  Verify bank account details
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>

      <footer className="fixed bottom-0 w-2/3 z-50">
        <div className="flex justify-end p-4">
          {/* <button
            className={`bg-slate-500 text-white py-2 px-10 rounded m-2 ${isFormValid
              ? "cursor-pointer opacity-100"
              : "cursor-not-allowed opacity-50"
              }`}
            disabled={!isFormValid}
            onClick={handleSaveAsDraft}
          >
            Save As Draft
          </button> */}
          <button
            className="bg-slate-500 text-white py-2 px-10 rounded m-2"
            onClick={handleSaveAsDraft} 
          >
            Save As Draft
          </button>
          <button
            className={`bg-green-600 text-white py-2 px-10 rounded m-2 ${isFormValid
              ? "cursor-pointer opacity-100"
              : "cursor-not-allowed opacity-50"
              }`}
            disabled={!isFormValid}
            onClick={handleSubmit}
          >
            Next
          </button>
        </div>
      </footer>

      {showModal && <RequiredRegistrationDetails toggleModal={toggleModal} />}

      {showReferModal && (
        <ReferrerModal
          toggleReferModal={toggleReferModal}
          onClickSuccess={() => {
            toggleReferModal();
          }}
        />
      )}

      {showLogoutModal && (
        <LogoutModel
          onClickLogout={confirmLogout}
          onClickCancel={() => setShowLogoutModal(false)}
        />
      )}
    </div>
  );
};

export default RestaurantDocuments;
