// services/api.js
import axios from "axios";
import { refreshTokenAPI } from "../services/AuthApi";
import { logoutService } from "./LogoutService";


// Function to get the latest base URL from localStorage
const getBaseUrl = () => {
  const endpoint = localStorage.getItem("endpoint");
  console.log(endpoint, "GetBaseURL");
  return `${process.env.REACT_APP_API_ENDPOINT}/${endpoint || ""}`;
};

// Create Axios instance with a dynamic base URL
const ApiService = axios.create({
  baseURL: getBaseUrl(),
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

// Function to update the base URL when the endpoint changes
export const updateApiBaseUrl = () => {
  ApiService.defaults.baseURL = getBaseUrl();
  console.log("Updated ApiService baseURL:", ApiService.defaults.baseURL);
};

// Helper function to parse the expiry time string and return a Date object
const parseExpiryTime = (expiryString) => {
  return new Date(expiryString.replace(" ", "T"));
};

// Axios interceptor to check token expiry and refresh if needed
ApiService.interceptors.request.use(
  async (config) => {
    try {
      // Get access token, refresh token, and token expiry times from localStorage
      const accessToken = localStorage.getItem("accessToken");
      const refreshToken = localStorage.getItem("refreshToken");
      const accessTokenExpiry = localStorage.getItem("accessTokenExpiry");
      const refreshTokenExpiry = localStorage.getItem("refreshTokenExpiry");

      // Log the current base URL dynamically
      console.log("Current Base URL:", ApiService.defaults.baseURL);

      // Parse expiry strings into Date objects
      const accessTokenExpiryDate = accessTokenExpiry
        ? parseExpiryTime(accessTokenExpiry)
        : null;
      const refreshTokenExpiryDate = refreshTokenExpiry
        ? parseExpiryTime(refreshTokenExpiry)
        : null;

      console.log("AccessToken Expiry Date:", accessTokenExpiryDate);
      console.log("RefreshToken Expiry Date:", refreshTokenExpiryDate);

      // Continuously updated current time from localStorage
      const now = new Date(parseInt(localStorage.getItem("currentTime")));

      console.log("Current Time from Local Storage:", now);
      console.log("Actual Current Time:", new Date());

      // Check if access token is expired or will expire within 1 minute
      const accessTokenExpiresIn = accessTokenExpiryDate - now;
      console.log("AccessToken Expires In (ms):", accessTokenExpiresIn);

      const refreshTokenExpiresIn = refreshTokenExpiryDate - now;
      console.log("RefreshToken Expires In (ms):", refreshTokenExpiresIn);

      // If the access token is about to expire within 1 minute or already expired
      if (accessTokenExpiresIn < 65000) {
        console.log("Access token is expired or about to expire.");

        // Check if refresh token is still valid
        if (refreshToken && refreshTokenExpiresIn > 0) {
          console.log(
            "Refresh token is valid. Attempting to refresh access token..."
          );

          try {
            // Attempt to refresh the access token
            const refreshToken = localStorage.getItem("refreshToken");
            const responseRefreh = await refreshTokenAPI(refreshToken);
            console.log("Refresh token API response:", responseRefreh);

            // Validate the response
            if (!responseRefreh || !responseRefreh.accessToken) {
              console.error(
                "Refresh token response is missing expected properties."
              );
              logoutService("Session");
              throw new Error("Session expired. Please log in again.");
            }

            // Update localStorage with new tokens and expiry times
            localStorage.setItem("accessToken", responseRefreh.accessToken);
            localStorage.setItem("refreshToken", responseRefreh.refreshToken);
            localStorage.setItem(
              "accessTokenExpiry",
              responseRefreh.accessTokenExpiry
            );
            localStorage.setItem(
              "refreshTokenExpiry",
              responseRefreh.refreshTokenExpiry
            );

            // Set the new access token in the request headers
            config.headers.Authorization = `Bearer ${responseRefreh.accessToken}`;
            console.log("New access token set in request headers.");
          } catch (error) {
            console.error("Error refreshing access token:", error);
            // Log out the user if refresh fails
            logoutService("Session");
            throw error;
          }
        } else {
          console.warn("Refresh token expired or invalid. Logging out.");
          logoutService("Session");
          throw new Error("Session expired. Please log in again.");
        }
      } else {
        // If the access token is still valid, ensure it's set in the request headers
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
          console.log("Access token is still valid, setting in headers.");
        }
      }

      return config;
    } catch (error) {
      console.error("Error in request interceptor:", error);
      return Promise.reject(error);
    }
  },
  (error) => {
    // Handle request errors
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

export const logout = async (logoutType) => {
  try {
    // Manually specify the base URL for logout
    const customBaseUrl = `${process.env.REACT_APP_API_ENDPOINT}/api`;
    const response = await axios.post(`${customBaseUrl}/logout`, {
      logoutType,
    });
    // const response = await ApiService.post("/logout", { logoutType });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const restaurantId = localStorage.getItem("restaurant_Id");
console.log("while Fetching", restaurantId);

// Function to check onboarding status by restaurant ID
export const checkOnboardingStatus = async (restaurantId) => {
  try {
    if (!restaurantId) {
      throw new Error("restaurantId is required");
    }

    const response = await ApiService.get(`/restaurants/onboarding/status`, {
      params: { restaurantId:restaurantId },
    });

    console.log("Onboarding Status Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching onboarding status:", error);
    if (error.response) {
      console.error("API Error:", error.response.data);
    }
    throw error;
  }
};

// Function to retrieve verification status by restaurant ID
export const getVerificationStatus = async (restaurantId) => {
  try {
    if (!restaurantId) {
      throw new Error("restaurantId is required");
    }

    const response = await ApiService.get(
      `/restaurants/onboarding/verificationStatus`,
      {
        params: { restaurantId: restaurantId },
      }
    );

    console.log("Verification Status Response:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching verification status:", error);
    if (error.response) {
      console.error("API Error:", error.response.data);
    }
    throw error;
  }
};

export const fetchActiveSearchCategories = async () => {
  try {
    const response = await ApiService.get(
      "/restaurants/active_search_categories"
    );
    console.log(response, "response");

    return response.data.search_categories;
  } catch (error) {
    console.error("Error fetching search categories:", error);
    throw error;
  }
};

export const uploadPanImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get(`/restaurants/register/pan_upload`, {
      params: { fileType, restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading PAN image:", error);
    throw error;
  }
};

export const confirmPanUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/pan_upload/upload_confirmation`,
      { metadataId, isUploadSuccess, restaurantId }
    );
    return response.data;
  } catch (error) {
    console.error("Error confirming PAN upload:", error);
    throw error;
  }
};

export const fetchPanUrl = async (restaurantId) => {
  try {
    console.log("Token for fetch", localStorage.getItem("accessToken"));

    const response = await ApiService.get(
      `/restaurants/register/pan_url/fetch`,
      {
        params: { restaurantId: restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deletePanUrl = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(`/restaurants/register/pan/delete`, {
      url,
      restaurantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadGstImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get(`/restaurants/register/gst_upload`, {
      params: { fileType, restaurantId: restaurantId },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading GST image:", error);
    throw error;
  }
};

export const confirmGstUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/gst_upload/upload_confirmation`,
      { metadataId, isUploadSuccess, restaurantId }
    );
    return response.data;
  } catch (error) {
    console.error("Error confirming GST upload:", error);
    throw error;
  }
};

export const fetchGstUrl = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/gst_url/fetch`,
      {
        params: { restaurantId: restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGstUrl = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(`/restaurants/register/gst/delete`, {
      url,
      restaurantId,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadFssaiImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/fssai_upload`,
      {
        params: { fileType, restaurantId: restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const confirmFssaiUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/fssai_upload/upload_confirmation`,
      {
        metadataId,
        isUploadSuccess,
        restaurantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchFssaiUrl = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      `/restaurants/register/fssai_url/fetch`,
      {
        params: { restaurantId: restaurantId },
      }
    );
    console.log("Restaurant ID in FSSAI", restaurantId);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteFssaiUrl = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(
      `/restaurants/register/fssai/delete`,
      { url, restaurantId }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Generate Store Image Upload Presigned URL
export const uploadStoreImage = async (fileType, restaurantId) => {
  try {
    console.log("Token in Stor Images", localStorage.getItem("accessToken"));

    const response = await ApiService.get("/restaurants/store_image/upload", {
      params: { fileType, restaurantId },
    });
    return response.data;
  } catch (error) {
    console.error("Error generating store image upload URL:", error);
    throw error;
  }
};

// Confirm Store Image Upload
export const confirmStoreImageUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post("/restaurants/store_image/confirm", {
      metadataId,
      isUploadSuccess,
      restaurantId,
    });
    return response.data;
  } catch (error) {
    console.error("Error confirming store image upload:", error);
    throw error;
  }
};

// Generate Profile Image Upload Presigned URL
export const uploadProfileImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/profile_image/upload", {
      params: { fileType, restaurantId },
    });
    return response.data;
  } catch (error) {
    console.error("Error generating profile image upload URL:", error);
    throw error;
  }
};

// Confirm Profile Image Upload
export const confirmProfileImageUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post(
      "/restaurants/profile_image/confirm",
      { metadataId, isUploadSuccess, restaurantId }
    );
    return response.data;
  } catch (error) {
    console.error("Error confirming profile image upload:", error);
    throw error;
  }
};

// Generate Food Image Upload Presigned URL
export const uploadFoodImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/food_image/upload", {
      params: { fileType, restaurantId },
    });
    return response.data;
  } catch (error) {
    console.error("Error generating food image upload URL:", error);
    throw error;
  }
};

// Confirm Food Image Upload
export const confirmFoodImageUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post("/restaurants/food_image/confirm", {
      metadataId,
      isUploadSuccess,
      restaurantId,
    });
    return response.data;
  } catch (error) {
    console.error("Error confirming food image upload:", error);
    throw error;
  }
};

// Generate Delivery Menu Image Upload Presigned URL
export const uploadDeliveryMenuImage = async (fileType, restaurantId) => {
  try {
    const response = await ApiService.get(
      "/restaurants/delivery_menu_image/upload",
      {
        params: { fileType, restaurantId },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error generating delivery menu image upload URL:", error);
    throw error;
  }
};

// Confirm Delivery Menu Image Upload
export const confirmDeliveryMenuImageUpload = async (
  metadataId,
  isUploadSuccess,
  restaurantId
) => {
  try {
    const response = await ApiService.post(
      "/restaurants/delivery_menu_image/confirm",
      { metadataId, isUploadSuccess, restaurantId }
    );
    return response.data;
  } catch (error) {
    console.error("Error confirming delivery menu image upload:", error);
    throw error;
  }
};

// Fetch Store Image URLs
export const fetchStoreImages = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/store_image/fetch", {
      // params: { restaurantId },
      params: { restaurantId: restaurantId },
    });
    return response.data.storeImageUrls;
  } catch (error) {
    console.error("Error fetching store images:", error);
    throw error;
  }
};

// Delete Store Image
export const deleteStoreImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post("/restaurants/store_image/delete", {
      url,
      restaurantId,
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting store image:", error);
    throw error;
  }
};

// Fetch Profile Image URL
export const fetchProfileImage = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/profile_image/fetch", {
      // params: { restaurantId },
      params: { restaurantId: restaurantId },
    });
    return response.data.profile_image_url;
  } catch (error) {
    console.error("Error fetching profile image:", error);
    throw error;
  }
};

// Delete Profile Image
export const deleteProfileImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/profile_image/delete",
      { url, restaurantId }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting profile image:", error);
    throw error;
  }
};

// Fetch Food Image URLs
export const fetchFoodImages = async (restaurantId) => {
  try {
    const response = await ApiService.get("/restaurants/food_image/fetch", {
      // params: { restaurantId },
      params: { restaurantId: restaurantId },
    });
    return response.data.foodImageUrls;
  } catch (error) {
    console.error("Error fetching food images:", error);
    throw error;
  }
};

// Delete Food Image
export const deleteFoodImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post("/restaurants/food_image/delete", {
      url,
      restaurantId,
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting food image:", error);
    throw error;
  }
};

// Fetch Delivery Menu Image URLs
export const fetchDeliveryMenuImages = async (restaurantId) => {
  try {
    const response = await ApiService.get(
      "/restaurants/delivery_menu_image/fetch",
      {
        // params: { restaurantId },
        params: { restaurantId: restaurantId },
      }
    );
    return response.data.deliveryMenuImageUrls;
  } catch (error) {
    console.error("Error fetching delivery menu images:", error);
    throw error;
  }
};

// Delete Delivery Menu Image
export const deleteDeliveryMenuImage = async (url, restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/delivery_menu_image/delete",
      { url, restaurantId }
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting delivery menu image:", error);
    throw error;
  }
};

// Verification KYC ALL

// PreVerify function to hit the /preVerify endpoint
const preVerify = async (verificationRequestType, requestData) => {
  try {
    const response = await ApiService.post(
      "restaurants/restaurant_onboarding/preVerify",
      {
        verificationRequestType,
        requestData,
      }
    );

    // Extract the metadata ID from the response
    const metadataId = response.data.requestMetaDataId;

    // Trigger the KYC verification in the background
    kycVerify(metadataId);

    return response.data;
  } catch (error) {
    handleErrorResponse(error);
  }
};

// KYC Verification function to hit the AWS Lambda endpoint using metadata ID
const kycVerify = async (metadataId) => {
  try {
    const kycResponse = await axios.post(
      "https://julmlx3e336wb37vh23riusoue0nolaz.lambda-url.ap-south-1.on.aws/",
      {
        kyc_verification_metadata_id: metadataId,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    console.log("KYC Verification Response:", kycResponse.data);
  } catch (error) {
    handleErrorResponse(error);
  }
};

// Function to handle and log different error responses
const handleErrorResponse = (error) => {
  if (error.response) {
    // Server responded with an error
    console.error("Error Response:", error.response.data);
    throw new Error(error.response.data.message || "Request failed.");
  } else if (error.request) {
    // No response received
    console.error("No response received:", error.request);
    throw new Error("No response from server.");
  } else {
    // Other errors
    console.error("Error:", error.message);
    throw new Error("An unexpected error occurred.");
  }
};

// Functions for specific document verifications

// PAN Verification
export const verifyPAN = async (panNumber, fullName) => {
  const requestData = { panNumber, fullName };
  return await preVerify("PAN", requestData);
};

// GST Verification
export const verifyGST = async (gstNumber, businessAddress) => {
  const requestData = { gstNumber, businessAddress };
  return await preVerify("GST", requestData);
};

// FSSAI Verification
export const verifyFSSAI = async (fssaiNumber, expiryDate) => {
  const requestData = { fssaiNumber, expiryDate };
  return await preVerify("FSSAI", requestData);
};

// BANK Verification
export const verifyBANK = async (bankAcNumber, ifsc_code, account_type) => {
  const requestData = { bankAcNumber, ifsc_code, account_type };
  return await preVerify("BANK", requestData);
};

export const sendOtpToOwner = async (phoneNumber, restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/restaurant_number_preverification",
      {
        phoneNumber,
        restaurantId,
      }
    );

    console.log("OTP sent successfully:", response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const verifyOwnerOtp = async (phoneNumber, otp) => {
  try {
    const response = await ApiService.post(
      "/restaurants/restaurant_number_verification",
      {
        phoneNumber,
        otp,
      }
    );
    console.log("Otp verified successfully");
    return response.data;
  } catch (error) {
    throw error;
  }
};

// For sending otp after signing document
export const sendOtp = async (restaurantId) => {
  try {
    const response = await ApiService.post(
      "/restaurants/onboarding/agreement_auth",
      {
        agreementType: "Merchant_Onboarding",
        restaurantId,
      }
    );
    return response.data;
  } catch (error) {
    throw error; // Will be caught by the error handler
  }
};

// For storing Restaurant Info data
export const updateRestaurantInfo = async (restaurantData) => {
  try {
    const response = await ApiService.post("/restaurants/restaurant_onboarding",
      {
        requestType: "update",
        requestPage: "res_info",
        data: restaurantData,
      });
    return response.data;
  } catch (error) {
    throw error; 
  }
};

// For storing Menu Operation data
export const updateMenuOperation = async (restaurantData) => {
  try {
    const response = await ApiService.post("/restaurants/restaurant_onboarding",
      {
        requestType: "update",
        requestPage: "menu_ops_details",
        data: restaurantData,
      });
    return response.data;
  } catch (error) {
    throw error; 
  }
};

// API function to update restaurant documents
export const updateRestaurantDocuments = async (documentData) => {
  try {
    const response = await ApiService.post(
      "/restaurants/restaurant_onboarding",
      {
        requestType: "update",
        requestPage: "res_documents",
        data: documentData,
      },
    );
    console.log("Restaurant documents updated successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error updating restaurant documents:", error);
    throw error;
  }
};
