import React, { useState, useEffect, useRef } from "react";
import "./Registration.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { logoutService } from "../services/LogoutService";
import LogoutModel from "./LogoutModel";
import { APP_NAME } from "../constants";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";
import { checkOnboardingStatus, updateRestaurantInfo } from "../services/Api";
import { sendOtpToOwner, verifyOwnerOtp } from "../services/Api";
import { handleError } from "../services/errorHandler";
import { fetchCurrentUTCTime } from "../services/AuthApi";
import { FaCrosshairs } from "react-icons/fa6";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";

const Registration = () => {
  const navigate = useNavigate();
  const inputRefs = useRef([]);

  const [latitude, setLatitude] = useState(""); // Latitude input state
  const [longitude, setLongitude] = useState(""); // Longitude input state
  const [showMapModal, setShowMapModal] = useState(false); // To control map modal visibility
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 }); // Map center state
  const [markerPosition, setMarkerPosition] = useState({ lat: 0, lng: 0 }); // Marker position for dragging
  // const restaurantId = localStorage.getItem("restaurant_Id");


  // Handle showing the map modal
  const handleShowMap = () => {
    setMarkerPosition({ lat: latitude || 0, lng: longitude || 0 }); // Initialize marker position
    setShowMapModal(true); // Show the map modal
  };

  const handleCloseMap = () => setShowMapModal(false); // Close the map modal

  // Get current location for map center when the map modal opens
  useEffect(() => {
    if (showMapModal && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setMapCenter({ lat: latitude, lng: longitude });
        setMarkerPosition({ lat: latitude, lng: longitude });
      });
    }
  }, [showMapModal]);

  // Places autocomplete functionality
  const { ready, value, setValue, suggestions, clearSuggestions } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: markerPosition.lat, lng: markerPosition.lng },
      radius: 200 * 1000, // 200km
    },
  });

  const handleSelectPlace = async (description) => {
    try {
      const results = await getGeocode({ address: description });
      const { lat, lng } = await getLatLng(results[0]);
      setMarkerPosition({ lat, lng });
      setMapCenter({ lat, lng });
      clearSuggestions(); // Clear suggestions after a place is selected
    } catch (error) {
      console.error("Error fetching location details:", error);
    }
  };

  // Handle Done button click to update latitude and longitude in the input fields
  const handleDoneClick = () => {
    setLatitude(markerPosition.lat); // Set latitude from marker position
    setLongitude(markerPosition.lng); // Set longitude from marker position
    setShowMapModal(false); // Close the map modal
  };

  // Update marker position on drag end
  const handleMarkerDragEnd = (event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };
  const [state, setState] = useState({
    restaurantName: "",
    restaurantNameError: '', // Error message for restaurant name
    inputsDisabled: false,
    ownerName: "",
    ownerNameError: "",
    ownerEmailError: "",
    restaurantPhoneNumber: "",
    ownerEmail: "",
    shopNo: "",
    floor: "",
    area: "",
    isSameAsOwner: false,
    showModal: false,
    showReferModal: false,
    showOtpModal: false,
    isOtpValid: false,
    otp: Array(6).fill(''),
    timer: 30,
    isOtpVerified: false,
    showLogoutModal: false,
    isFormValid: false,
    restaurantId: localStorage.getItem("restaurant_Id"),
    latitude: "28.6304",
    longitude: "77.2177",
    cityState: "New Delhi, Delhi",
  });

  useEffect(() => {
    const syncTime = async () => {
      try {
        const utcTime = await fetchCurrentUTCTime();  // Fetch the UTC time from API
        const utcTimestamp = new Date(utcTime);  // Convert fetched time to Date object
        console.log("UTC Time Stamp", utcTimestamp);
        localStorage.setItem("currentTime", utcTimestamp.getTime().toString()); // Store timestamp in milliseconds
      } catch (error) {
        console.error("Failed to sync time:", error);
      }
    };

    syncTime(); // Sync the time initially

    const interval = setInterval(() => {
      const storedTime = parseInt(localStorage.getItem("currentTime"), 10); // Parse the stored timestamp as an integer
      if (storedTime) {
        const updatedTime = storedTime + 1000; // Increment by 1000 milliseconds (1 second)
        localStorage.setItem("currentTime", updatedTime.toString());
        console.log("Updated Time:", new Date(updatedTime)); // Log the updated time as a Date object
      }
    }, 1000);

    // Clear the interval when component unmounts
    return () => clearInterval(interval);
  }, []);


  // Logout Modal
  const confirmLogout = () => {
    setState((prevState) => ({
      ...prevState,
      showLogoutModal: false,
    }));
    logoutService("Session");
  };
  // For displaying modal - Mandatory modal
  const toggleModal = () => {
    setState((prevState) => ({
      ...prevState,
      showModal: !prevState.showModal,
    }));
  };

  // For displaying referral modal
  const toggleReferModal = () => {
    setState((prevState) => ({
      ...prevState,
      showReferModal: !prevState.showReferModal
    }));
  };

  // Validate restaurant name input to accept only text and spaces, max 50 characters
  const handleInputChange = (e, fieldName) => {
    const value = e.target.value;
    let errorMessage = "";

    if (fieldName === "restaurantName" || fieldName === "ownerName") {
      const regex = /^[A-Za-z\s]*$/; // Allows only letters and spaces

      // Check if the input is empty
      if (value === "") {
        errorMessage = `${fieldName} is required.`;
      }
      // Validate for special characters and numbers
      else if (!regex.test(value)) {
        errorMessage = `${fieldName} should contain only letters and spaces.`;
      }
      // Check minimum length (e.g., minimum 3 characters)
      else if (value.length < 3) {
        errorMessage = `${fieldName} must be at least 3 characters long.`;
      }
      // Check maximum length (e.g., maximum 50 characters)
      else if (value.length > 50) {
        errorMessage = `${fieldName} must be less than 50 characters.`;
      }
    }

    // Email validation
    else if (fieldName === "ownerEmail") {
      // Define the email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
      const consecutiveDotsRegex = /\.{2,}/;

      if (value === "") {
        errorMessage = "Email address is required.";
      }
      // Validate the general email format
      else if (!emailRegex.test(value)) {
        errorMessage = "Please enter a valid email address.";
      }
      // Check for consecutive dots in email
      else if (consecutiveDotsRegex.test(value)) {
        errorMessage = "Email address cannot contain consecutive dots.";
      }
      // Ensure email does not contain spaces
      else if (/\s/.test(value)) {
        errorMessage = "Email address cannot contain spaces.";
      }
    }

    // Update state with the input value and the error message
    setState((prevState) => ({
      ...prevState,
      [fieldName]: value, // Update the corresponding field (restaurantName, ownerName, or ownerEmail)
      [`${fieldName}Error`]: errorMessage, // Update the error message
    }));
  };

  // For verifying phone number (allows only digits)
  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setState((prevState) => ({
        ...prevState,
        restaurantPhoneNumber: value,
      }));
    }
  };

  const handleOtpChange = (index, value) => {
    if (value.length === 1 && /^\d*$/.test(value)) {
      const newOtp = [...state.otp];
      newOtp[index] = value;
      setState(prevState => ({
        ...prevState,
        otp: newOtp,  // Updates the OTP in the state
        isOtpValid: newOtp.join('').length === 6 // Check if OTP is fully entered
      }));

      if (index < 5) {
        inputRefs.current[index + 1]?.focus();  // Focus on the next input field
      }
    } else if (value === '') {
      const newOtp = [...state.otp];
      newOtp[index] = '';  // Clear the current input
      setState(prevState => ({
        ...prevState,
        otp: newOtp,  // Update the OTP in the state
        isOtpValid: false // Set OTP valid to false if any field is cleared
      }));
      inputRefs.current[index]?.focus();  // Stay on the same field
    }
  };

  // Handle backspace key press in OTP fields
  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace') {
      const newOtp = [...state.otp];
      newOtp[index] = '';  // Clear current digit only
      setState(prevState => ({
        ...prevState,
        otp: newOtp,  // Update the OTP in the state
      }));
      // Move focus to the previous input, but don't clear it
      if (index > 0 && state.otp[index] === '') {
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  // For sending otp
  const phone = `+91${state.restaurantPhoneNumber}`;
  localStorage.setItem("restaurantPhoneNumber", phone);

  // Verify Button function
  const handleVerifyButtonClick = async (e) => {
    e.preventDefault();

    if (state.inputsDisabled) {
      return; // Don't allow verification if the inputs are disabled
    }

    try {
      await sendOtpToOwner(phone, state.restaurantId);
      setState((prevState) => ({
        ...prevState,
        showOtpModal: true,
      }))
    } catch (error) {
      handleError(error);
    }
  };

  // For resending otp
  const handleResendOtp = async () => {

    if (state.inputsDisabled) {
      return; // Don't allow verification if the inputs are disabled
    }

    try {
      await sendOtpToOwner(phone, state.restaurantId);
      setState((prevState) => ({
        ...prevState,
        otp: Array(6).fill(""),
      }));
      inputRefs.current[0]?.focus();
    } catch (error) {
      handleError(error);
    }
  };

  // For verifing otp
  const handleVerifyOtp = async (e) => {

    if (state.inputsDisabled) {
      return; // Don't allow verification if the inputs are disabled
    }

    const finalOtp = state.otp.join("")
    e.preventDefault();
    try {
      await verifyOwnerOtp(phone, finalOtp);
      setState((prevState) => ({
        ...prevState,
        showOtpModal: false,
        isOtpVerified: true,
        inputsDisabled: true, // Disable input fields after OTP verification
      }));
    } catch (error) {
      handleError(error);
    }
  };

  // Timer management useEffect
  useEffect(() => {
    let timerInterval;
    if (state.showOtpModal && state.timer > 0) {
      timerInterval = setInterval(() => {
        setState((prevState) => ({
          ...prevState,
          timer: prevState.timer - 1,
        }));
      }, 1000);
    } else if (state.timer === 0) {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);
  }, [state.showOtpModal, state.timer]);

  // Validate Form
  useEffect(() => {
    const isValid =
      state.restaurantName.trim() !== "" &&
      state.ownerName.trim() !== "" &&
      state.ownerEmail.trim() !== "" &&
      state.restaurantPhoneNumber.length === 10 &&
      state.area.trim() !== "" &&  // Area
      state.cityState.trim() !== "" &&  // City and State
      state.latitude !== "" &&  // Latitude
      state.longitude !== "";  // Longitude
    setState((prevState) => ({
      ...prevState,
      isFormValid: isValid,
    }));
  }, [state.restaurantName, state.ownerName, state.ownerEmail, state.restaurantPhoneNumber, state.area, state.cityState, state.latitude, state.longitude]);

  // Redirect if not logged in
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  const restaurantInfoData = {
    restaurant_name: state.restaurantName,
    owner_full_name: state.ownerName,
    owner_contact_number: state.restaurantPhoneNumber,
    owner_email_id: state.ownerEmail,
    restaurant_primary_contact: phone,
    latitude: state.latitude,
    longitude: state.longitude,
    shop_no: state.shopNo,
    floor: state.floor,
    area: state.area,
    city_state: state.cityState,
  };

  // For saving Restaurant Info Data
  const handleSaveDraft = () => {

    alert("SAVE AS DRAFT WORKING")

    if (state.inputsDisabled) {
      return; // Don't allow verification if the inputs are disabled
    }

    // Stores the data in localStorage
    localStorage.setItem("restaurantInfoData", JSON.stringify(restaurantInfoData));

    console.log("Restaurant Info Data:", restaurantInfoData);
  };

  // Next Button function
  const handleNextClick = async () => {

    if (state.inputsDisabled) {
      return; // Don't allow verification if the inputs are disabled
    }

    try {
      const response = await updateRestaurantInfo(restaurantInfoData)
      console.log("", response);

      // Stores the data in localStorage
      localStorage.setItem("restaurantInfoData", JSON.stringify(restaurantInfoData));
      localStorage.setItem("isRestaurantInfoSaved", "true"); // A flag to indicate that the form has been saved

      // Disable input fields
      setState((prevState) => ({
        ...prevState,
        inputsDisabled: true, // Disable the inputs after saving
      }));

      if (state.isFormValid) {
        navigate("/menu-operational"); // Assuming this is the route for the next page
      } else {
        alert("Please fill all the required fields correctly.");
      }
    } catch (error) {
      console.error("Error updating restaurant info:", error);
      throw error;
    }
  };

  useEffect(() => {
    const savedData = localStorage.getItem("restaurantInfoData");
    const isSaved = localStorage.getItem("isRestaurantInfoSaved");

    if (savedData) {
      const parsedData = JSON.parse(savedData);

      setState((prevState) => ({
        ...prevState,
        restaurantName: parsedData.restaurant_name || "",
        ownerName: parsedData.owner_full_name || "",
        restaurantPhoneNumber: parsedData.owner_contact_number || "",
        ownerEmail: parsedData.owner_email_id || "",
        latitude: parsedData.latitude || "",
        longitude: parsedData.longitude || "",
        shopNo: parsedData.shop_no || "",
        floor: parsedData.floor || "",
        area: parsedData.area || "",
        cityState: parsedData.city_state || "",
        inputsDisabled: isSaved === "true", // Disable inputs if the data has been saved
      }));
    }
  }, []);


  //  Don't Remove this code
  // useEffect(() => {
  //   const fetchOnboardingStatus = async () => {
  //     try {

  //       const statusData = await checkOnboardingStatus(restaurantId); // Call the service function
  //       const { isSubmitted, isSigned } = statusData;

  //       if (isSubmitted && !isSigned) {
  //         navigate('/sign-document'); // Redirect to /sign-document if not signed
  //       } else if (isSigned) {
  //         navigate('/complete-sign'); // Redirect to /complete-sign if signed
  //       }
  //     } catch (error) {
  //       console.error('Error fetching onboarding status:', error);
  //       setError('Failed to fetch onboarding status.');
  //     }
  //   };

  //   fetchOnboardingStatus(); // Call the API when the component mounts
  // }, [navigate]);


  return (

    <div className="registration bg-gray-100 min-h-screen flex flex-col mt-10">
      <main className="pt-12 pb-20 flex-1 w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="content flex-1 bg-gray-100 p-6 overflow-y-auto">
          <div className="flex flex-col">
            <h1 className="text-3xl font-bold mb-4">Restaurant information</h1>
            {/* Restaurant Name */}
            <div className="bg-white shadow-lg rounded-lg p-5 w-full">
              <h2 className="text-2xl font-bold ">Restaurant name</h2>
              <p className="text-gray-600 font-medium text-sm mb-2">
                Customers will see this name on {APP_NAME}
              </p>
              <hr className="-mx-6" />
              <form className="mt-3">
                <input type="text" value={state.restaurantName} onChange={(e) => handleInputChange(e, "restaurantName")}
                  className={`mt-2 p-2 w-full rounded-lg border ${state.restaurantNameError ? 'border-red-500' : 'border-gray-300'} outline-none shadow-sm sm:text-sm`}
                  placeholder="Restaurant name*" maxLength="20" disabled={state.inputsDisabled} />
                {state.restaurantNameError && (
                  <p className="text-red-500 text-sm mt-1">
                    {state.restaurantNameError}
                  </p>
                )}
              </form>
            </div>
            {/* Owner  Details*/}
            <div className="bg-white shadow-lg rounded-lg p-5 w-full mt-4">
              <h2 className="text-2xl font-bold">Owner details</h2>
              <p className="text-sm font-medium text-gray-600 mb-2">
                {APP_NAME} will use these details for all business
                communications and updates
              </p>
              <hr className="-mx-6" />
              <form className="mt-3">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <input type="text" value={state.ownerName} onChange={(e) => handleInputChange(e, "ownerName")}
                    className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                    placeholder="Full name*" disabled={state.inputsDisabled} />
                  {state.ownerNameError && (
                    <p className="text-red-500 text-sm mt-1">{state.ownerNameError}</p> // Error message in red
                  )}
                </div>
                <div>
                  <input type="email" value={state.ownerEmail} onChange={(e) => handleInputChange(e, "ownerEmail")}
                    className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                    placeholder="Email Address*" disabled={state.inputsDisabled} />
                  {state.ownerEmailError && (
                    <p className="text-red-500 text-sm mt-1">{state.ownerEmailError}</p> // Email error message in red
                  )}
                </div>
                </div>

                <div className="mb-4">
                  <label className="text-lg font-medium">
                    Restaurant's owner contact number
                  </label>
                  <p className="text-sm text-gray-500 mb-2">
                    Customers, delivery partners, and {APP_NAME} may call on this
                    number for order support </p>
                    <div className="flex items-center border border-gray-300 outline-none shadow-sm sm:text-sm rounded p-2">
                      <div className="flex items-center">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/b/bc/Flag_of_India.png"
                          alt="India Flag" className="w-6 h-4 mr-2" />
                        <span className="text-gray-700">+91</span>
                      </div>
                      <input type="tel" placeholder="Phone number*" value={state.restaurantPhoneNumber}
                        onChange={handlePhoneNumberChange} minLength="10" maxLength="10"
                        className="ml-2 outline-none flex-1 border-l border-gray-300 pl-2" required disabled={state.inputsDisabled} />
                      {state.restaurantPhoneNumber.length === 10 && (
                        <button
                          className={`verify-btn text-white ml-2 px-3 py-1 rounded ${state.isOtpVerified ? 'bg-green-500' : ''}`}
                          onClick={handleVerifyButtonClick} disabled={state.isOtpVerified}>
                          {state.isOtpVerified ? "Verified" : "Verify"}
                        </button>
                      )}
                   
                  </div>
                </div>
              </form>
            </div>

            {/* Restaurant Address Details */}
            <div className="bg-white shadow-lg rounded-lg px-5 pt-5 w-full mt-4">
              <h2 className="text-2xl font-bold">Restaurant address details</h2>
              <p className="text-sm font-medium text-gray-600 mb-6">
                Address details are based on the restaurant location mentioned
                above
              </p>
              <form>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  {/* Latitude Input */}
                  <div className="relative">
                    <input
                      type="text"
                      value={latitude}
                      readOnly
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Latitude"
                    />
                    <FaCrosshairs
                      className="absolute right-3 top-3 text-green-500 text-xl cursor-pointer"
                      onClick={handleShowMap} // Open map modal on click
                    />
                  </div>
                  {/* Longitude Input */}
                  <div className="relative">
                    <input
                      type="text"
                      value={longitude}
                      readOnly
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="Longitude"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <input type="text" placeholder="Shop no. (optional)" value={state.shopNo}
                      onChange={(e) =>
                        setState((prevState) => ({ ...prevState, shopNo: e.target.value }))}
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm" disabled={state.inputsDisabled} />
                  </div>
                  <div>
                    <input type="text" placeholder="Floor (optional)" value={state.floor}
                      onChange={(e) =>
                        setState((prevState) => ({ ...prevState, floor: e.target.value }))
                      }
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm" disabled={state.inputsDisabled} />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                  <div>
                    <input type="text" placeholder="Area / Sector / Locality*" value={state.area}
                      onChange={(e) =>
                        setState((prevState) => ({ ...prevState, area: e.target.value }))
                      }
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm" disabled={state.inputsDisabled} />
                  </div>
                  <div>
                    <input type="text" value={state.cityState} readOnly
                      className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm"
                      placeholder="City/State" disabled={state.inputsDisabled} />
                  </div>
                </div>
                <div className="mb-4">
                <input type="text" placeholder="Add any nearby landmark (optional)"
                  className="mt-2 p-2 w-full rounded-md border border-gray-300 outline-none shadow-sm sm:text-sm" disabled={state.inputsDisabled} />
                <p className="address-note text-sm px-2 py-2 rounded-md mt-4">
                  Please ensure that this address is the same as mentioned on
                  your FSSAI license
                </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
      <footer className="fixed bottom-0 w-2/3">
        {/* Save Button */}
        <div className="flex justify-center md:justify-end sm:justify-end p-4 space-x-4">
          <button className="bg-slate-500 text-white py-2 px-10 rounded w-full md:w-auto h-12 flex justify-center items-center whitespace-nowrap"
            onClick={handleSaveDraft}>
            Save As Draft
          </button>
          {/* Next Button */}
          <button
            className={`bg-green-600 text-white py-2 px-10 rounded w-full md:w-auto h-12 flex justify-center items-center ${state.isFormValid ? "cursor-pointer" : "cursor-not-allowed opacity-50"
              }`} onClick={handleNextClick} disabled={!state.isFormValid}>
            Next
          </button>
        </div>
      </footer>
      {/* Rendering Required registration details page */}
      {state.showModal && <RequiredRegistrationDetails toggleModal={toggleModal} />}


      {
        showMapModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
            <div className="bg-white rounded-lg shadow-lg w-3/4 h-3/4 p-6 relative">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold">Choose Location</h2>

                <div className="flex space-x-4">
                  {/* Done Button */}
                  <button
                    onClick={handleDoneClick} // Call the done function
                    className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded shadow-md"
                  >
                    Done
                  </button>

                  {/* Close Button */}
                  <button
                    onClick={handleCloseMap} // Call the close function
                    className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded shadow-md"
                  >
                    Close
                  </button>
                </div>
              </div>

              {/* Search Input */}
              <div className="mb-4">
                <input
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  disabled={!ready}
                  placeholder="Search places..."
                  className="p-2 w-full rounded-md border border-gray-300 shadow-sm"
                />
                <div className="absolute z-10 bg-white shadow-lg w-full max-h-40 overflow-y-auto">
                  {suggestions.status === "OK" &&
                    suggestions.data.map((suggestion) => (
                      <div
                        key={suggestion.place_id}
                        className="p-2 cursor-pointer hover:bg-gray-200"
                        onClick={() => handleSelectPlace(suggestion.description)}
                      >
                        {suggestion.description}
                      </div>
                    ))}
                </div>
              </div>

              {/* Google Map */}
              <LoadScript googleMapsApiKey="AIzaSyCq6d5p6M2B43U-dqa06A7mFn6tZ2bvs7g" libraries={["places"]}>
                <GoogleMap
                  center={mapCenter}
                  zoom={13}
                  mapContainerStyle={{ height: "400px", width: "100%" }}
                >
                  {/* Draggable Marker */}
                  <Marker
                    position={markerPosition}
                    draggable={true} // Allow marker to be draggable
                    onDragEnd={handleMarkerDragEnd} // Update position when dragging ends
                  />
                </GoogleMap>
              </LoadScript>
            </div>
          </div>
        )
      }

      {state.showReferModal && (
        <ReferrerModal
          toggleReferModal={toggleReferModal}
          onClickSuccess={() => {
            toggleReferModal();
          }}
        />
      )}

      {state.showOtpModal && (
        // Otp verification section
        <div className="fixed inset-0 flex items-center justify-center bg-gray-950 bg-opacity-75 z-50">
          <div className="bg-white rounded-lg shadow-lg w-96 p-6">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-semibold">OTP Verification</h2>
              <button
                onClick={() =>
                  setState((prevState) => ({
                    ...prevState,
                    showOtpModal: false,
                  }))
                }
                className="text-gray-500 hover:text-gray-700"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="text-center">
              <p className="text-gray-500">Check text messages for your OTP</p>
              <div className="flex justify-center mt-4 space-x-2">
                {state.otp.map((digit, index) => (
                  <input key={index} type="text" maxLength="1"
                    className="w-10 h-10 border rounded text-center shadow-md focus:outline-none focus:ring-2 focus:ring-red-500"
                    value={digit} onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    ref={(el) => inputRefs.current[index] = el} disabled={state.isOtpVerified || state.inputsDisabled}
                  />
                ))}
              </div>
              <div className="mt-4">
                <button
                  className={`bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded ${state.otp.join('').length === 6 ? '' : 'opacity-50 cursor-not-allowed'}`}
                  onClick={handleVerifyOtp}
                  disabled={state.otp.join('').length !== 6}
                >
                  Verify OTP
                </button>
              </div>
              <div className="mt-2 text-gray-500">
                Not received OTP? <a href="#" className="text-red-500 hover:underline" onClick={handleResendOtp} >Resend Now</a>
              </div>
            </div>
          </div>
        </div>)
      }
      {
        state.showLogoutModal && (
          <LogoutModel
            onClickLogout={confirmLogout}
            onClickCancel={() =>
              setState((prevState) => ({
                ...prevState,
                showLogoutModal: false,
              }))
            }
          />
        )
      }
    </div >
  );
};
export default Registration;
