import React, { useState, useEffect } from "react";
import "./Registration.css";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { logoutService } from "../services/LogoutService";
import LogoutModel from "./LogoutModel";
import { APP_NAME, COMMISSION } from "../constants";
import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
import ReferrerModal from "./ReferrerModal";

const Contract = () => {
  const navigate = useNavigate();

  const [state, setState] = useState({
    showModal: false,
    showReferModal: false,
    showLogoutModal: false,
    restaurantName: "",
    ownerName: "",
    businessAddress: "123 Main St",
    gstNumber: "123456789012",
    bankDetails: "Bank Account Details",
    fssaiDetails: "FSSAI Details",
    isChecked: false, // State for checkbox
    isFormValid: false, // State for button enable/disable
  });

  // Extract restaurant name and owner name from localStorage
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    const restaurantInfo = JSON.parse(localStorage.getItem("restaurantInfoData")); // Get the stored data as an object
  
    if (!token) {
      navigate("/");
    } else if (restaurantInfo) {
      setState((prevState) => ({
        ...prevState,
        restaurantName: restaurantInfo.restaurant_name, 
        ownerName: restaurantInfo.owner_full_name, 
      }));
    }
  }, []);

  // Toggle Modal functions
  const toggleModal = () => {
    setState((prevState) => ({ ...prevState, showModal: !prevState.showModal }));
  };
  
  const toggleReferModal = () => {
    setState((prevState) => ({
      ...prevState,
      showReferModal: !prevState.showReferModal,
    }));
  };

  // Handle checkbox change
  const handleCheckboxChange = () => {
    setState((prevState) => ({
      ...prevState,
      isChecked: !prevState.isChecked,
      isFormValid: !prevState.isChecked, // Enable button if checkbox is checked
    }));
  };

  // Next button
  const handleNextClick = () => {
    if (state.isFormValid) {
      navigate("/sign-document");
    }
  };

  // Logout function
  const confirmLogout = () => {
    setState((prevState) => ({
      ...prevState,
      showLogoutModal: false,
    }));
    logoutService("Session");
  };

  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      <main className="flex-grow flex pt-20 pb-20 w-full">
        <div className="content flex-1 overflow-y-auto bg-gray-100 px-6 py-1">
          <h3 className="mt-5 text-3xl font-bold">Review merchant details</h3>

          <label htmlFor="fullName" className="block mt-5 text-sm font-medium text-gray-700">
            Restaurant Name*
          </label>
          <input
            type="text"
            id="restaurantName"
            value={state.restaurantName}
            readOnly
            className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
          />

          <label htmlFor="panNumber" className="block mt-5 text-sm font-medium text-gray-700">
            Owner Name*
          </label>
          <input
            type="text"
            id="ownerName"
            value={state.ownerName}
            readOnly
            className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
          />

          <label htmlFor="businessAddress" className="block mt-5 text-sm font-medium text-gray-700">
            Business Address
          </label>
          <input
            type="text"
            id="businessAddress"
            value={state.businessAddress}
            readOnly
            className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
          />

          <label htmlFor="gstNumber" className="block mt-5 text-sm font-medium text-gray-700">
            GST Number
          </label>
          <input
            type="text"
            id="gstNumber"
            value={state.gstNumber}
            readOnly
            className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
          />

          <label htmlFor="bankDetails" className="block mt-5 text-sm font-medium text-gray-700">
            Bank Account Details
          </label>
          <input
            type="text"
            id="bankDetails"
            value={state.bankDetails}
            readOnly
            className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
          />

          <label htmlFor="fssaiDetails" className="block mt-5 text-sm font-medium text-gray-700">
            FSSAI Details
          </label>
          <input
            type="text"
            id="fssaiDetails"
            value={state.fssaiDetails}
            readOnly
            className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none"
          />

          <p className="mt-5 text-2xl font-bold">
            In {APP_NAME}, we are providing very low commission, only{" "}
            {COMMISSION}
          </p>

          <form className="mt-5">
            <div className="checkbox-container flex items-center">
              <input
                type="checkbox"
                id="terms"
                name="terms"
                className="mr-2"
                checked={state.isChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="terms" className="text-sm">
                I agree to the{" "}
                <a href="#" className="text-blue-500 underline">
                  terms and conditions
                </a>
              </label>
            </div>
          </form>

          <button
            className={"text-white text-center rounded px-5 py-2 mt-5 bg-green-600 cursor-pointer mr-4"}
            onClick={() => navigate("/previous-page")}
          >
            Back
          </button>

          <button
            onClick={handleNextClick}
            disabled={!state.isFormValid}
            className={`text-white text-center rounded px-5 py-2 mt-5 ${
              state.isFormValid ? "bg-green-600" : "bg-gray-400 cursor-not-allowed"
            }`}
          >
            Proceed to Sign
          </button>
        </div>
      </main>

      {/* Modal code here */}
      {state.showModal && <RequiredRegistrationDetails toggleModal={toggleModal} />}
      {state.showReferModal && (
        <ReferrerModal
          toggleReferModal={toggleReferModal}
          onClickSuccess={() => {
            toggleReferModal();
          }}
        />
      )}
      {state.showLogoutModal && (
        <LogoutModel
          onClickCancel={confirmLogout}
          onClickLogout={() => setState((prevState) => ({ ...prevState, showLogoutModal: false }))}
        />
      )}
    </div>
  );
};

export default Contract;


// import React, { useState, useEffect } from "react";
// import "./Registration.css";
// import { useNavigate } from "react-router-dom";
// import "react-datepicker/dist/react-datepicker.css";
// import { logoutService } from "../services/LogoutService";
// import LogoutModel from "./LogoutModel";
// import { APP_NAME, COMMISSION } from "../constants";
// import RequiredRegistrationDetails from "./RequiredRegistrationDetails";
// import ReferrerModal from "./ReferrerModal";

// // Custom Input Component to restrict manual input
// // const CustomInput = forwardRef(({ value, onClick }, ref) => (
// //     <div className="relative w-full">
// //         <input
// //             className="block w-full border border-gray-300 outline-none rounded-md pl-3 pr-8 py-1"
// //             onClick={onClick}
// //             value={value}
// //             ref={ref}
// //             readOnly
// //         />
// //         <FontAwesomeIcon icon={faChevronDown} className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 pointer-events-none" />
// //     </div>
// // ));

// const Contract = () => {
//   const navigate = useNavigate();

//   const [state, setState] = useState({
//     showModal: false,
//     showReferModal: false,
//     showLogoutModal: false,
//     restaurantName: "Sample Restaurant",
//     ownerName: "John Doe",
//     businessAddress: "123 Main St",
//     gstNumber: "123456789012",
//     bankDetails: "Bank Account Details",
//     fssaiDetails: "FSSAI Details",
//     isChecked: false, // State for checkbox
//     isFormValid: false, // State for button enable/disable
//   });

//   // Toggle Modal functions
//   const toggleModal = () => {
//     setState((prevState) => ({ ...prevState, showModal: !prevState.showModal }));
//   };
//   const toggleReferModal = () => {
//     setState((prevState) => ({
//       ...prevState,
//       showReferModal: !prevState.showReferModal,
//     }));
//   };
//   // Handle checkbox change
//   const handleCheckboxChange = () => {
//     setState((prevState) => ({
//       ...prevState,
//       isChecked: !prevState.isChecked,
//       isFormValid: !prevState.isChecked, // Enable button if checkbox is checked
//     }));
//   };
//   // Redirect function 
//   useEffect(() => {
//     const token = localStorage.getItem("accessToken");
//     if (!token) {
//       navigate("/");
//     }
//   }, [navigate]);
//   // Next button 
//   const handleNextClick = () => {
//     if (state.isFormValid) {
//       navigate("/sign-document");
//     }
//   };
//   // Logout function
//   const confirmLogout = () => {
//     setState ((prevState) =>({
//       ...prevState, showLogoutModal:false
//     }));
//     logoutService("Session");
//   }


//   return (
//     <div className="min-h-screen bg-gray-100 flex flex-col">
//       <main className="flex-grow flex pt-20 pb-20 w-full">
//         <div className="content flex-1 overflow-y-auto bg-gray-100 px-6 py-1">
//           <h3 className="mt-5 text-3xl font-bold">Review merchant details</h3>
//           <label htmlFor="fullName" className="block mt-5 text-sm font-medium text-gray-700">
//             Full Name*
//           </label>
//           <input type="text" id="fullName" value={state.restaurantName} readOnly
//             className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none" />

//           <label htmlFor="panNumber" className="block mt-5 text-sm font-medium text-gray-700">
//             PAN Number*
//           </label>
//           <input type="text" id="panNumber" value={state.ownerName} readOnly
//             className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none" />

//           <label htmlFor="businessAddress" className="block mt-5 text-sm font-medium text-gray-700">
//             Business Address
//           </label>
//           <input type="text" id="businessAddress" value={state.businessAddress} readOnly
//             className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none" />

//           <label htmlFor="gstNumber" className="block mt-5 text-sm font-medium text-gray-700">
//             GST Number
//           </label>
//           <input type="text" id="gstNumber" value={state.gstNumber} readOnly
//             className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none" />

//           <label htmlFor="bankDetails" className="block mt-5 text-sm font-medium text-gray-700">
//             Bank Account Details
//           </label>
//           <input type="text" id="bankDetails" value={state.bankDetails} readOnly
//             className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none" />

//           <label htmlFor="fssaiDetails" className="block mt-5 text-sm font-medium text-gray-700">
//             FSSAI Details
//           </label>
//           <input type="text" id="fssaiDetails" value={state.fssaiDetails} readOnly
//             className="mt-1 block w-full p-2 border rounded border-gray-300 shadow-sm bg-gray-200 focus:outline-none" />

//           <p className="mt-5 text-2xl font-bold">
//             In {APP_NAME}, we are providing very low commission, only{" "}
//             {COMMISSION}
//           </p>

//           <form className="mt-5">
//             <div className="checkbox-container flex items-center">
//               <input type="checkbox" id="terms" name="terms" className="mr-2" checked={state.isChecked}
//                 onChange={handleCheckboxChange} />
//               <label htmlFor="terms" className="text-sm">
//                 I agree to the{" "}
//                 {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
//                 <a href="#" className="text-blue-500 underline">
//                   terms and conditions
//                 </a>
//               </label>
//             </div>
//           </form>
//           <button
//             className={"text-white text-center rounded px-5 py-2 mt-5 bg-green-600 cursor-pointer mr-4"}>
//             Back
//           </button>

//           <button onClick={handleNextClick} disabled={!state.isFormValid} className={`text-white text-center rounded px-5 py-2 mt-5  ${state.isFormValid ? "bg-green-600" : "bg-gray-400 cursor-not-allowed"
//             }`}>
//             Proceed to Sign
//           </button>
//         </div>
//       </main>

//       {/* Modal code here */}
//       {/* Rendering Required Registration details code */}
//       {state.showModal && <RequiredRegistrationDetails toggleModal={toggleModal} />}
//       {/* Referre Modal Component rendering code */}
//       {state.showReferModal && (
//         <ReferrerModal
//           toggleReferModal={toggleReferModal}
//           onClickSuccess={() => {
//             toggleReferModal();
//           }}
//         />
//       )}
//       {/* Model for Logout */}
//       {state.showLogoutModal && (
//         <LogoutModel
//           onClickCancel={confirmLogout}
//           onClickLogout={() => setState((prevState) => ({ ...prevState, showLogoutModal: false }))}
//         />
//       )}
//     </div>
//   );
// };
// export default Contract;





