import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Dinning_Delivery from '../images/dinning_delivery.webp';
import Dinning from '../images/dinning.webp';
import Delivery from '../images/delivery.webp';
import { Link } from 'react-router-dom';
import './Service.css';

const Service = ({ onClose }) => {
    const navigate = useNavigate();
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg p-6">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-semibold">Select the service you want to register for</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                </div>
                <hr className="horizontal-line mb-4 -mx-6" />
                <div className="service-option mb-4 flex items-center px-6 py-4 shadow-sm border rounded-lg hover:bg-gray-100">
                    <div className="flex-grow">
                        <h3 className="text-base font-semibold">Both food delivery & dining</h3>
                        <p className="text-sm text-gray-600">List your restaurant on both the delivery and dining sections</p>
                        <Link to="/register" className="doc-content">Register Now</Link>
                    </div>
                    <div className="w-20 h-20 ml-4 flex-shrink-0">
                        <img src={Dinning_Delivery} alt="Both food delivery & dining" className="object-contain w-full h-full" />
                    </div>
                </div>
                <div className="service-option mb-4 flex items-center px-6 py-4 shadow-sm border rounded-lg hover:bg-gray-100">
                    <div className="flex-grow">
                        <h3 className="text-base font-semibold">Food delivery only</h3>
                        <p className="text-sm text-gray-600">List your restaurant in the delivery section only</p>
                        <a href="#" className="text-sm text-blue-500">Register now</a>
                    </div>
                    <div className="w-20 h-20 ml-4 flex-shrink-0">
                        <img src={Delivery} alt="Food delivery only" className="object-contain w-full h-full" />
                    </div>
                </div>
                <div className="service-option flex items-center px-6 py-4 shadow-sm border rounded-lg hover:bg-gray-100">
                    <div className="flex-grow">
                        <h3 className="text-base font-semibold">Dining only</h3>
                        <p className="text-sm text-gray-600">List your restaurant in the dining section only</p>
                        <a href="#" className="text-sm text-blue-500">Register now</a>
                    </div>
                    <div className="w-20 h-20 ml-4 flex-shrink-0">
                        <img src={Dinning} alt="Dining only" className="object-contain w-full h-full" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Service;
