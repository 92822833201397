import React, { useState } from 'react';
import Logo from '../images/logo.webp';
import { logoutService } from '../services/LogoutService';
import LogoutModel from "./LogoutModel";
import { APP_NAME } from "../constants";

function CompleteSign() {
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const confirmLogout = () => {
    setShowLogoutModal(false);
    logoutService("Session");
  };
  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <header className="w-full bg-white shadow-md flex items-center justify-between p-3">
        <div className="flex items-center">
          <img src={Logo} alt="Logo" className="h-12" />
        </div>
        <div className="flex items-center">
          <button
            className="bg-green-600 text-white py-2 px-10 rounded cursor-pointer"
            onClick={() => setShowLogoutModal(true)}
          >
            Logout
          </button>
        </div>
      </header>

      <p className="text-center mt-48 text-2xl">
        You have successfully completed the onboarding process. <br />
        <p className="mt-3 text-green-500">Welcome to The {APP_NAME} Family!</p>
        <br />
        You will receive your copy of the merchant agreement over email. <br />
        Someone from our team will get in-touch with you shortly to help you go
        live on our platform. <br />
      </p>

      {showLogoutModal && (
        <LogoutModel
          onClickCancel={confirmLogout}
          onClickLogout={() => setShowLogoutModal(false)}
        />
      )}
    </div>
  );
}

export default CompleteSign
