import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Registration from "./components/Registration";
import RestaurantDocuments from "./components/RestaurantDocuments";
import MenuOperational from "./components/MenuOperational";
import RestaurantInformation from "./components/RestaurantInformation";
import Contract from "./components/Contract";
import SignDocument from "./components/SignDocument";
import CompleteSign from "./components/CompleteSign";
import RedirectToLastPage from "./utils/RedirectToLastPage";
import TrackLastPage from "./utils/TrackLastPage";
import Layout from "./components/Layout";

function App() {

  return (
    <Router>
      <RedirectToLastPage />
      <TrackLastPage />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route element={<Layout />}>
          <Route path="/register" element={<Registration />} />
          <Route path="/menu-operational" element={<MenuOperational />} />
          <Route
            path="/restaurant-documents"
            element={<RestaurantDocuments />}
          />
          <Route
            path="/restaurant-information"
            element={<RestaurantInformation />}
          />
          <Route path="/contract" element={<Contract />} />
          <Route path="/sign-document" element={<SignDocument />} />
          <Route path="/complete-sign" element={<CompleteSign />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
