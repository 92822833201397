import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReferrerModal = ({ toggleReferModal, onClickSuccess }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-3 w-full max-w-sm">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Email ID of referrer</h2>
          <button
            onClick={toggleReferModal}
            className="text-gray-400 hover:text-gray-600"
          >
            <FontAwesomeIcon icon={faTimes} className="text-xl" />
          </button>
        </div>
        <hr className="-mx-3 border-gray-300 mb-4" />
        <div className="mb-4">
          <input
            type="email"
            className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-1 focus:ring-lime-500"
            placeholder="Email id"
          />
        </div>
        <hr className="-mx-3 border-gray-300 mb-4" />
        <div className="flex justify-center">
          <button
            onClick={onClickSuccess}
            className="okay-btn text-white py-2 px-6 rounded-lg"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReferrerModal;
